import React, { createContext, useContext, useState, useEffect } from "react";

// Definir el tipo de los mensajes
interface Message {
  temperatura?: number;
  conductividad?: number;
  voltaje_device?: number;
  timestamp?: string;
}

// Crear el contexto
const MessageFeedContext = createContext<{
  messageFeed: Message[];
  addMessage: (message: Message) => void;
}>({
  messageFeed: [],
  addMessage: () => {},
});

// Proveedor del contexto
export const MessageFeedProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [messageFeed, setMessageFeed] = useState<Message[]>([]);

  // Función para agregar mensajes nuevos al historial
  const addMessage = (newMessage: Message) => {
    setMessageFeed((prevFeed) => [...prevFeed, newMessage].slice(-100)); // Máximo 100 mensajes
  };

  return (
    <MessageFeedContext.Provider value={{ messageFeed, addMessage }}>
      {children}
    </MessageFeedContext.Provider>
  );
};

// Hook para usar el contexto
export const useMessageFeed = () => useContext(MessageFeedContext);
