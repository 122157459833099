import Card from "../UI/Card";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "",
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem: any) {
          let value = tooltipItem.parsed.y; // Asegurar que obtenemos el valor correcto
          let datasetLabel = tooltipItem.dataset.label || "";

          if (datasetLabel.toLowerCase().includes("conductividad")) {
            return `${datasetLabel}: ${value} µS/cm`;
          } else if (datasetLabel.toLowerCase().includes("voltaje")) {
            return `${datasetLabel}: ${value} V`;
          } else if (datasetLabel.toLowerCase().includes("temperatura")) {
            return `${datasetLabel}: ${value} °C`;
          }

          return `${datasetLabel}: ${value}`;
        },
      },
    },
  },
  scales: {
    x: {
      type: "time" as const,
      gridLines: {
        lineWidth: 2,
      },
      time: {
        unit: "minute" as const,
        displayFormats: {
          minute: "HH:mm:ss",
          hour: "HH:mm",
          day: "MMM DD",
        },
      },
    },
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
  },
};

interface Message {
  timestamp?: string;
  [key: string]: any; // Para permitir cualquier métrica (temperatura, conductividad, etc.)
}

const RealTimeGraph = (props: {
  title: string;
  label: string;
  borderColor: string;
  backgroundColor: string;
  messageFeed: Message[]; // Recibimos el feed completo
  metricKey: string; // Clave de la métrica a graficar (ej: "temperatura", "conductividad")
}) => {
  const [data, setData] = useState({
    labels: [] as Date[],
    datasets: [] as any[],
  });

  useEffect(() => {
    // Transformar el messageFeed en datos para el gráfico
    const labels = props.messageFeed
      .filter((message) => message.timestamp) // Filtramos mensajes con timestamp válido
      .map((message) => new Date(message.timestamp!)); // Convertimos a Date

    const values = props.messageFeed
      .filter((message) => message[props.metricKey] !== undefined) // Filtramos mensajes con la métrica válida
      .map((message) => message[props.metricKey]); // Extraemos los valores de la métrica

    // Actualizamos los datos del gráfico
    setData({
      labels,
      datasets: [
        {
          label: props.label,
          data: labels.map((timestamp, index) => ({
            x: timestamp,
            y: values[index],
          })),
          borderColor: props.borderColor,
          backgroundColor: props.backgroundColor,
          yAxisID: "y",
          pointRadius: 0,
        },
      ],
    });
  }, [props.messageFeed, props.metricKey]); // Actualizar cuando cambie el feed o la métrica

  return (
    <section>
      <Card title={props.title}>
        <div>
          {data.labels.length === 0 && "No existen datos para mostrar."}
          {data.labels.length > 0 && <Line options={options} data={data} />}
        </div>
      </Card>
    </section>
  );
};

export default RealTimeGraph;