import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useEffect, useState } from "react";
import classes from "./NotificationIcon.module.css";
import {
  NotificationsResponse,
  makeNotification,
} from "../../utils/helpers/notificationMaker";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import Link from "@mui/material/Link";
import { useNotificationContext } from "../../NotificationContext";
import { useMessageFeed } from "../../MessageFeedContext";
import { useHistoricalDataContext } from "../../HistoricalDataContext";


const NotificationIcon = () => {
  const nots: NotificationsResponse = [];

  const { addMessage } = useMessageFeed(); // Usamos el contexto para agregar mensajes de tipo "data"

  const { notifications, addNotification, clearNotifications } =
    useNotificationContext();
  const {
    setAlertStartDate,
    setAlertEndDate,
    setGeneralStartDate,
    setGeneralEndDate,
  } = useHistoricalDataContext();

  const [open, setOpen] = useState(false);

  const [bagdeNumber, setBagdeNumber] = useState(notifications.length);

  const [read, setRead] = useState(false);

  const authCtx = useContext(AuthContext);

  let email = authCtx.userEmail;

  const wssEndpoint = process.env.REACT_APP_WEB_SOCKET_EXTERNAL_ENDPOINT!;
  const [wsState, setWsState] = useState<undefined | WebSocket>(undefined);
  // creo el socket fuera del useEffect para evitar crea un socket nuevo en cada render del componente
  let ws: WebSocket = new WebSocket(wssEndpoint);

  useEffect(() => {
    ws.onclose = () => {
      console.log(`Closed Connection for user: ${email}, Reconnecting...`);
      ws = new WebSocket(wssEndpoint);
      console.log("Reconnected");
    };
  }, []);

  // un socket puede estar en abierto pero todavía no listo para mandar datos,
  // esta función controla que el socket este abierto y en estado listo antes de enviar,
  // en caso de no estarlo agenda un reintento para dentro de 1 segundo.
  const handleSend = () => {
    if (ws.readyState === WebSocket.OPEN) {
      ws.send("USER:" + email);
    } else if (ws.readyState === WebSocket.CLOSED) {
      console.log("WebSocket connection closed. Reconnecting...");
      ws = new WebSocket(wssEndpoint);
      console.log("Reconnected");
      setTimeout(() => handleSend(), 1000);
    } else {
      console.log("WebSocket not ready. Reconnecting...");
      setTimeout(() => handleSend(), 1000);
    }
  };

  useEffect(() => {
    if (wssEndpoint) {
      setWsState(ws);
      ws.onopen = () => {
        console.log("Opened Connection!");
        if (email === "") {
          email = "test@test";
        }
        handleSend();
      };
    }
  }, []);

  useEffect(() => {

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
  
        if (data.type === "alert") {
          const notification = makeNotification(data);
          if (notification) {
            addNotification(notification);
            setBagdeNumber(notifications.length);
            console.log("Mensaje de tipo alert agregado al context notification:", notification);
          }
        } else if (data.type === "data") {
          addMessage(data); // Agrega mensaje al contexto de messageFeed
          console.log("Mensaje de tipo data agregado al context feed:", data);
        } else {
          console.warn("Mensaje de control recibido:", data);
        }
      } catch (error) {
        console.error("Error procesando mensaje WebSocket:", error);
      }
    };
  
  }, []);

  useEffect(() => {
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  }, []);

  useEffect(() => {
    setBagdeNumber(notifications.length);
    setRead(false);
  }, [notifications]);

  const toggleDialog = () => {
    if (!open && !read) {
      setRead(true);
    }
    if (notifications.length) {
      setOpen((open) => !open);
    }
  };

  const commitNot = () => {
    if (wsState) {
      wsState.send("COMMIT:" + email);
    } else {
      // Queue a retry
      setTimeout(() => {
        commitNot();
      }, 3000);
    }
  };

  const handleNotificationClick = (notification: any) => {
    if (notification.recvTime) {
      console.log("Timestamp de la notificación seleccionada: " + notification.recvTime);
  
      // Validar el formato de recvTime
    
      const startDate = new Date(notification.recvTime);
  
      if (isNaN(startDate.getTime())) {
        console.error("Fecha de notificación inválida:", notification.recvTime);
        return;
      }
  
      const offsetGeneralDate = Number(process.env.REACT_APP_OFFSET_GENERAL_DATE || 0);
      const endDate = new Date(startDate.getTime() + 1000); // Añadir 1 segundo para el rango de fecha
  
      console.log("notification: " + JSON.stringify(notification));
      console.log("Fecha de notificación clickeada: ", startDate);
  
      console.log("start date: " + startDate);
      setAlertStartDate(startDate);
      console.log("end date: " + endDate)
      setAlertEndDate(endDate);
      console.log("start date get time:" + startDate.getTime());
      //TODO usar variable de entorno (offsetGeneralDate) en lugar de la constante
      setGeneralStartDate(new Date(startDate.getTime() - 7200000));
      setGeneralEndDate(new Date());
    } else {
      console.log("La notificación no tiene una fecha definida.");
    }
  };
  

  return (
    <IconButton
      color="inherit"
      onClick={toggleDialog}
      className={classes.icon_container}
    >
      <Badge badgeContent={read ? 0 : bagdeNumber} color="secondary">
        <NotificationsIcon />
      </Badge>
      {open ? (
        <div className={classes.dialog}>
          {notifications.map((noti, index) => (
            <div
              key={index}
              className={classes.noti_container_read}
              onClick={() => handleNotificationClick(noti)}
            >
              <div>
                <strong>{noti.contextName}: </strong>
                <span>{noti.action}</span>
              </div>
              <div className={classes.date_container}>
                {/* Verificar si la fecha está definida antes de intentar formatearla */}
                <small>
                  {noti.recvTime
                    ? new Date(noti.recvTime).toLocaleString()
                    : "Fecha no disponible"}
                </small>
              </div>
            </div>
          ))}
          <Link onClick={commitNot}>
            <div className={classes.eliminar_texto}>Eliminar todas</div>
          </Link>
        </div>
      ) : (
        ""
      )}
    </IconButton>
  );
};

export default NotificationIcon;
