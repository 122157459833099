import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useMessageFeed } from "../MessageFeedContext";
import RealTimeGraph from "../components/Devices/RealTimeGraph";
import IconWithValue from "../components/Devices/IconWithValue";
import Card from "../components/UI/Card";
import {FaWater, FaBatteryEmpty, FaBatteryQuarter, FaBatteryHalf, FaBatteryThreeQuarters, FaBatteryFull, FaTemperatureHigh } from "react-icons/fa";
import DashboardIcon from "../components/UI/DashboardIcon";

const DevicesPage = () => {
  const { messageFeed } = useMessageFeed(); // Obtenemos los mensajes del contexto

  // Función para obtener el dato más reciente de un atributo
  const getLatestValue = (key: string) => {
    if (messageFeed.length === 0) {
      return null; // Retornar null si el feed está vacío
    }
    const latestMessage = messageFeed[messageFeed.length - 1] as any; // Último mensaje
    return latestMessage[key] ?? null; // Devolver el valor de la clave o null
  };

  // Obtenemos los valores más recientes para cada métrica y formateamos los valores antes de usarlos en los componentes
  const temperatura = getLatestValue("temperatura") ? parseFloat(Number(getLatestValue("temperatura")).toFixed(2)) : 0.00;
  const conductividad = getLatestValue("conductividad") ? Math.round(getLatestValue("conductividad")) : 0;
  const voltaje = getLatestValue("voltaje_device") ? parseFloat(Number(getLatestValue("voltaje_device")).toFixed(2)) : 0.00;

  const timestamp = getLatestValue("timestamp");

  const getBatteryIcon = (voltaje: number) => {
    if (voltaje < 11.5) return <FaBatteryEmpty size={50} color="red" />; // Batería baja
    if (voltaje < 12) return <FaBatteryQuarter size={50} color="orange" />; // Batería baja-media
    if (voltaje < 12.5) return <FaBatteryHalf size={50} color="black" />; // Batería media
    if (voltaje < 13) return <FaBatteryThreeQuarters size={50} color="black" />; // Batería casi llena
    return <FaBatteryFull size={50} color="green" />; // Batería llena
  };

  // Log para verificar los valores actuales
  useEffect(() => {
    console.log("Últimos valores:");
    console.log("Temperatura:", temperatura);
    console.log("Conductividad:", conductividad);
    console.log("Voltaje:", voltaje);
    console.log("Timestamp:", timestamp);
  }, [messageFeed]);

  return (
    <div>
    <Card title="ISLA DE FLORES">
      {/* Header */}
      <Grid container spacing={0} columns={12} justifyContent="space-between" alignItems="center">
        <Grid item xs="auto">
          <DashboardIcon title="MONITOREO" />
        </Grid>
      </Grid>
      
      {/* Contenido principal */}
      <Grid container spacing={3}>
      {/* Fila para Temperatura */}
      <Grid item xs={9}>
        <RealTimeGraph
          title="EVOLUCIÓN DE TEMPERATURA DEL AGUA"
          label="Temperatura"
          borderColor="#18265a"
          backgroundColor="#18265a"
          messageFeed={messageFeed} // Pasar el feed completo
          metricKey="temperatura" // Especificar la métrica a graficar
        />
      </Grid>
      <Grid item xs={3}>
        <IconWithValue
          title="ACTUAL"
          value={temperatura}
          unit="°C"
          icon={<FaTemperatureHigh size={50} />}
        />
      </Grid>

      {/* Fila para Conductividad */}
      <Grid item xs={9}>
        <RealTimeGraph
          title="EVOLUCIÓN DE CONDUCTIVIDAD DEL AGUA"
          label="Conductividad"
          borderColor="#C21292"
          backgroundColor="#C21292"
          messageFeed={messageFeed} // Pasar el feed completo
          metricKey="conductividad" // Especificar la métrica a graficar
        />
      </Grid>
      <Grid item xs={3}>
        <IconWithValue
          title="ACTUAL"
          value={conductividad}
          unit="µS/cm"
          icon={<FaWater size={50} />}
        />
      </Grid>

      {/* Fila para Voltaje */}
      <Grid item xs={9}>
        <RealTimeGraph
          title="EVOLUCIÓN DEL VOLTAJE DEL DISPOSITIVO"
          label="Voltaje"
          borderColor="#FFA732"
          backgroundColor="#FFA732"
          messageFeed={messageFeed} // Pasar el feed completo
          metricKey="voltaje_device" // Especificar la métrica a graficar
        />
      </Grid>
      <Grid item xs={3}>
        <IconWithValue
          title="ACTUAL"
          value={voltaje}
          unit="V"
          icon={getBatteryIcon(voltaje)}
        />
      </Grid>
    </Grid>
    </Card>
    </div>
    
  );
};

export default DevicesPage;
